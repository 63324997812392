import React from 'react'

import featherSprite from "../images/feather-sprite.svg";

const Icon = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox = "0 0 24 24"
			className={`feather feather-${props.name} ${props.classes}`}
		>
			<use xlinkHref={`${featherSprite}#${props.name}`} />
		</svg>
	)
}

export default Icon