import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Icon from "./Icon";

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="bg-nav fixed w-full top-0">
      <div className="flex flex-wrap items-center justify-between mx-auto pt-4 pl-4 pr-4 pb-1">
        <Link to="/" className="flex items-center no-underline ">
          <svg
            className="fill-current h-8 mr-2 w-8"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
          </svg>
          <span className="font-bold text-xl tracking-tight">{siteTitle}</span>
        </Link>

        

        <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          <div className="text-sm flex">
            <Link
              to="/"
              className="flex flex-col items-center mr-6 rounded navLink"
              activeClassName="navLinkActive"
            >
               <Icon name="home"  classes=""></Icon>           
                
                <span className="">Home</span>
            </Link>

            {/* <Link
              to="/writings"
              className="flex flex-col items-center mr-6 rounded navLink"
              activeClassName="navLinkActive"
            >
               <Icon name="edit-2" classes=""></Icon>           
               
               <span className="">Writings</span>
            </Link> */}

            <Link
              to="/about"
              className="flex flex-col  items-center mr-6 rounded navLink"
              activeClassName="navLinkActive"
            >
               
               <Icon name="mic" classes=""></Icon>           
               
              <span className="">About</span>
            </Link>

            <Link
              to="/contact"
              className="flex flex-col items-center mr-6 rounded navLink"
              activeClassName="navLinkActive" 
            >
              <Icon name="phone-call"  classes=""></Icon>    
              <span>Contact</span>
            </Link>

          </div>
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
