import React from "react";

function SubHeader() {
    return (<h2>
    <span className="hdr1 text-2xl font-bold inline-block my-8 p-3 text-gray-900 shadow">α alpha</span>
    <span className="hdr2 text-2xl font-bold inline-block my-8 p-3 text-gray-900 shadow">β Beta</span>
    <span className="hdr3 text-2xl font-bold inline-block my-8 p-3 text-gray-900 shadow">Π Prime</span>
    </h2>);
}

export default SubHeader;