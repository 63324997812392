import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import versionControlIllustration from "../images/undraw_version_control_v2.svg";
import SubHeader from "../components/subheader";
import './styles/pages.module.css';



function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`VersionPrime`, `devops`, `react`, `Angular`, `.NET Core`, `SPA` ]}
      />

      <section styleName="main-section">
        <img
          src={versionControlIllustration}
          className="block mx-auto w-1/2"
          
          alt="Version Prime Motto"
        />

        <SubHeader></SubHeader>

        <h3 styleName="caption">
          Consultancy providing product design/UX/Devops development. Specialised in SPA stacks. React, Angular, Node, .NET Core and Java. 
        </h3>
      </section>

    </Layout>
  );
}

export default IndexPage;
